<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Assign Marker</h1>
    </div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab title="Khóa học" active title-link-class="btn btn-light-info">
          <CourseList></CourseList>
        </b-tab>
        <b-tab title="Lớp học" title-link-class="btn btn-light-info">
          <ClassList></ClassList>
        </b-tab>
      </b-tabs>
    </b-card>
  </v-app>
</template>

<script>
export default {
  name: "TabMarker",
  components: {
    CourseList: () => import("./CourseList.vue"),
    ClassList: () => import("./ClassList.vue"),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.nav.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #6993ff !important;
}
.text-tab {
  color: #ffffff;
  background-color: #6993ff !important;
}
</style>
